@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

button.gm-control-active {
  min-width: auto;
}

.map-container {
  flex: 1;
  width: 100%;
  position: relative;
}

#map {
  height: 100%;
}

#map .info-box-container {
  word-wrap: break-word;
  min-width: 150px;
  max-width: 270px;
  min-height: 90px;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  font-size: 13px;
  font-family: "Source Sans Pro", sans-serif;
  color: #5f5f5f;
}

.info-box-container p {
  margin-top: 0;
  margin-bottom: 0;
}

.info-box-container .building-name {
  font-size: 14px;
  margin-bottom: 1em;
}

.info-box-container .close-icon {
  font-size: 12px;
  position: absolute;
  right: 6px;
  top: 6px;
}

.close-icon:hover {
  cursor: pointer;
}

.marker {
  position: absolute;
  height: 20px;
  width: 20px;
  transform: translate(-50%, -100%);
}

#search-box {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 240px;
  height: 32px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  left: 50%;
  margin-left: -120px;
}

.mg-top-5 {
  margin-top: 5px;
}

.mg-bottom-5 {
  margin-bottom: 5px;
}

[role="button"]:focus {
  outline: none !important;
}
