@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button.gm-control-active {
  min-width: auto;
}

.map-container {
  flex: 1 1;
  width: 100%;
  position: relative;
}

#map {
  height: 100%;
}

#map .info-box-container {
  word-wrap: break-word;
  min-width: 150px;
  max-width: 270px;
  min-height: 90px;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  font-size: 13px;
  font-family: "Source Sans Pro", sans-serif;
  color: #5f5f5f;
}

.info-box-container p {
  margin-top: 0;
  margin-bottom: 0;
}

.info-box-container .building-name {
  font-size: 14px;
  margin-bottom: 1em;
}

.info-box-container .close-icon {
  font-size: 12px;
  position: absolute;
  right: 6px;
  top: 6px;
}

.close-icon:hover {
  cursor: pointer;
}

.marker {
  position: absolute;
  height: 20px;
  width: 20px;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
}

#search-box {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 240px;
  height: 32px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  left: 50%;
  margin-left: -120px;
}

.mg-top-5 {
  margin-top: 5px;
}

.mg-bottom-5 {
  margin-bottom: 5px;
}

[role="button"]:focus {
  outline: none !important;
}

.cluster-marker {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cluster-marker > img {
  position: relative !important;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.auth {
  text-align: left;
  padding: 10px;
  color: "#5C5E5D";
}

.login {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  height: 100vh;
  background-color: #f8f9fd;
}

input[type="text"],
input[type="password"] {
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px;
}

input[type="submit"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #01d28e;
  border: 1px solid #01d28e;
  color: #fff;
  padding: 10px 20px;
}

input[type="submit"]:hover {
  background: #6cf0c2;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
  text-align: center;
}

.info {
  color: grey;
  font-size: 12px;
  text-align: center;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  margin: 10px;
}

