.cluster-marker {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cluster-marker > img {
  position: relative !important;
  transform: scale(1.1);
}

.auth {
  text-align: left;
  padding: 10px;
  color: "#5C5E5D";
}
